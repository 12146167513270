<template>
  <!-- 申请打包 -->
  <div class="container">
    <el-tabs
      v-model="activeName"
      type="card"
      @tab-click="handleClick"
    >
      <el-tab-pane
        label="第一步打包明细"
        name="first"
      >
        <div style="
            border-bottom: 1px silver solid;
            padding-bottom: 10px;
            margin-top: 10px;
            padding-left: 60px;
          ">
          <el-button
            type="warning"
            size="mini"
            @click="nextTab"
          >下一步</el-button>
        </div>
        <div
          class="headernumber"
          v-if="number"
        >打包批次号 : {{ number }}</div>
        <div class="newForm search">
          <el-form
            ref="searchForm"
            :model="searchForm"
            :rules="searchForm"
            label-width="100px"
          >
            <div class="inlineDiv">
              <div class="queryItem1">
                <el-form-item
                  label="运单号:"
                  prop="courierNumber"
                >
                  <el-input
                    v-model="courierNumber"
                    clearable
                    placeholder="请输入运单号"
                    @keyup.enter.native="cx"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="queryItem1">
                <el-form-item label="团号:">
                  <el-input
                    v-model="searchForm.invitationCode"
                    clearable
                    placeholder="请输入团号"
                    @keyup.enter.native="cx"
                  >
                  </el-input>
                </el-form-item>
              </div>
              <div class="queryItem1">
                <el-form-item
                  label="会员号:"
                  prop="memberId"
                >
                  <el-input
                    v-model="searchForm.memberId"
                    clearable
                    placeholder="请输入会员号"
                    @keyup.enter.native="cx"
                  >
                  </el-input>
                </el-form-item>
              </div>
              <div class="queryItem1">
                <el-form-item
                  label="仓库:"
                  prop="storageId"
                >
                  <el-select
                    v-model="searchForm.storageId"
                    clearable
                    placeholder="请选择仓库"
                    @change="storageIdchange"
                  >
                    <el-option
                      v-for="item in storeArr"
                      :key="item.id"
                      :label="item.storageName"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <el-button
                type="warning"
                size="mini"
                @click="cx"
              >查 询</el-button>
            </div>
          </el-form>
        </div>
        <div
          style="margin: 20px 0"
          v-if="tunNum"
        >团号: {{ tunNum }}</div>
        <div class="transfer">
          <el-row>
            <el-col :span="11">
              <div class="leftTable">
                <div class="titleTip">可选包裹</div>
                <el-table
                  :data="leftTable"
                  size="mini"
                  ref="leftTable"
                  @row-click="rowclick"
                  stripe
                  highlight-current-row
                  :show-overflow-tooltip="true"
                  border
                  height="87%"
                  @selection-change="notJoinSelectionChange"
                  style="width: 100%"
                >
                  <el-table-column
                    type="index"
                    label="序号"
                    align="center"
                    width="50"
                  >
                  </el-table-column>
                  <el-table-column
                    type="selection"
                    align="center"
                    width="55"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="courierNumber"
                    align="center"
                    label="快递单号"
                    min-width="100"
                    :show-overflow-tooltip="true"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="storageName"
                    align="center"
                    label="仓库"
                    min-width="100"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="nickname"
                    label="会员名称"
                    align="center"
                    min-width="100"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="memberId"
                    align="center"
                    min-width="100"
                    label="会员号"
                  >
                  </el-table-column>
                </el-table>
                <paging
                  ref="pags"
                  :pageNum="pageStart"
                  :total="pageCount"
                  :sizeList="sizeList"
                  :size="pageTotal"
                  @handleSizeChange="notJionSize"
                  @handleCurrentChange="notJoinCurrent"
                ></paging>
              </div>
            </el-col>
            <el-col :span="2">
              <div class="btnBox">
                <el-button
                  type="primary"
                  size="small"
                  style="margin-bottom: 40px"
                  @click="theLeft"
                  :disabled="status === 0"
                  icon="el-icon-arrow-left"
                >
                  向左移
                </el-button>
                <el-button
                  type="success"
                  size="small"
                  :disabled="status === 1"
                  @click="theRight"
                >
                  向右移 <i class="el-icon-arrow-right el-icon--right"></i>
                </el-button>
              </div>
            </el-col>
            <el-col :span="11">
              <div class="rightTable">
                <div class="titleTip">已选包裹</div>
                <el-table
                  :data="rightTable"
                  ref="rigthTable"
                  @row-click="rowclick2"
                  stripe
                  border
                  height="87%"
                  size="mini"
                  @selection-change="joinSelectionChange"
                  style="width: 100%"
                >
                  <el-table-column
                    type="index"
                    label="序号"
                    align="center"
                    width="50"
                  >
                  </el-table-column>
                  <el-table-column
                    type="selection"
                    align="center"
                    width="55"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="courierNumber"
                    align="center"
                    label="快递单号"
                    min-width="100"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="storageName"
                    align="center"
                    label="仓库"
                    min-width="100"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="nickname"
                    label="会员名称"
                    align="center"
                    min-width="100"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="memberId"
                    align="center"
                    min-width="100"
                    label="会员号"
                  >
                  </el-table-column>
                </el-table>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-tab-pane>
      <el-tab-pane
        :disabled="!this.memberId"
        label="第二步打包服务"
        name="second"
      >
        <div class="StepTow">
          <el-button
            type="warning"
            size="mini"
            @click="nextTab"
          >下一步</el-button>

          <el-checkbox-group v-model="checkList">
            <el-checkbox
              v-for="item in serveArr"
              :label="item.itemServiceId"
              :key="item.itemServiceId"
            >
              {{ item.title }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </el-tab-pane>
      <el-tab-pane
        :disabled="!this.memberId"
        label="第三步收件地址"
        name="third"
      >
        <div class="formBox">
          <div class="btns">
            <el-button
              type="warning"
              size="mini"
              @click="nextTab"
            >下一步</el-button>
          </div>

          <el-form
            ref="allFormRef"
            :rules="allFormRules"
            :model="allFormData"
            label-width="120px"
          >
            <div class="newForm threeForm">
              <div class="queryItem1">
                <el-form-item label="会员名称">
                  <el-input
                    disabled
                    v-model="nickname"
                  ></el-input>
                  <!-- <el-select disabled v-model="memberId" filterable clearable>
                    <el-option
                      v-for="(item, index) in huiyuanList"
                      :key="index"
                      :label="item.nickname"
                      :value="item.userId"
                    >
                    </el-option>
                  </el-select> -->
                </el-form-item>
              </div>
              <div class="queryItem1">
                <el-form-item label="公司名称:">
                  <el-input
                    v-model="allFormData.corporateName"
                    clearable
                  ></el-input>
                </el-form-item>
              </div>
              <div class="queryItem1">
                <el-form-item
                  label="收货人:"
                  prop="consigneeName"
                >
                  <el-input
                    v-model="allFormData.consigneeName"
                    clearable
                  ></el-input>
                </el-form-item>
              </div>
              <div class="queryItem1">
                <el-form-item
                  label="身份证号:"
                  prop="idcardNumber"
                >
                  <el-input
                    v-model="allFormData.idcardNumber"
                    clearable
                  ></el-input>
                </el-form-item>
              </div>
              <div class="queryItem1">
                <el-form-item
                  label="收货人电话:"
                  prop="receivingPhone"
                >
                  <el-input
                    v-model="allFormData.receivingPhone"
                    clearable
                  ></el-input>
                </el-form-item>
              </div>
              <div class="queryItem1">
                <el-form-item
                  label="国家/地区:"
                  prop="areaCountryId"
                >
                  <el-select
                    v-model="allFormData.areaCountryId"
                    filterable
                    clearable
                    placeholder="请选择国家123"
                  >
                    <el-option
                      v-for="(item, index) in areaContryArr"
                      :key="index"
                      :label="item.chineseName"
                      :value="item.areaCountryId"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="queryItem1">
                <!-- prop="provinceName" -->
                <el-form-item label="州\省:">
                  <el-input
                    v-model="allFormData.provinceName"
                    clearable
                  ></el-input>
                </el-form-item>
              </div>
              <div class="queryItem1">
                <el-form-item label="城市:">
                  <el-input
                    v-model="allFormData.cityName"
                    clearable
                  ></el-input>
                </el-form-item>
              </div>
              <div class="queryItem1">
                <el-form-item label="邮政编码:">
                  <el-input
                    v-model="allFormData.postCode"
                    clearable
                  ></el-input>
                </el-form-item>
              </div>
              <div class="queryItem1">
                <el-form-item
                  label="详细地址:"
                  prop="addressee"
                >
                  <el-input
                    v-model="allFormData.addressee"
                    clearable
                  ></el-input>
                </el-form-item>
              </div>
              <div
                class="queryItem1"
                v-if="NoHuoMao"
              >
                <el-form-item label="提货方式:">
                  <el-select
                    v-model="allFormData.handoverMode"
                    clearable
                    placeholder="请选择提货方式"
                  >
                    <el-option
                      v-for="(item, index) in handoverModeList"
                      :label="item.val"
                      :key="index"
                      :value="item.key"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div
                class="queryItem1"
                v-if="
                  NoHuoMao && allFormData.handoverMode == 'zi_ti:handover_mode'
                "
              >
                <el-form-item label="自提仓库:">
                  <el-select
                    v-model="allFormData.packupStorageId"
                    clearable
                    placeholder="请选择仓库"
                    @change="storageIdchange1"
                  >
                    <el-option
                      v-for="item in storeArr"
                      :key="item.id"
                      :label="item.storageName"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div
                class="queryItem1"
                v-if="
                  NoHuoMao && allFormData.handoverMode == 'zi_ti:handover_mode'
                "
              >
                <el-form-item label="自提仓库地址:">
                  <el-input
                    type="textarea"
                    rows="3"
                    v-model="allFormData.packupAddress"
                  ></el-input>
                </el-form-item>
              </div>

              <div
                class="queryItem1"
                v-if="
                  NoHuoMao &&
                  allFormData.handoverMode == 'bian_li_dian:handover_mode'
                "
              >
                <el-form-item label="是否代收货款:">
                  <el-select
                    v-model="adpas.isCollection"
                    clearable
                    placeholder="请选择是否代收货款"
                  >
                    <el-option
                      label="是"
                      value="Y"
                    > </el-option>
                    <el-option
                      label="否"
                      value="N"
                    > </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div
                class="queryItem1"
                v-if="
                  NoHuoMao &&
                  allFormData.handoverMode == 'bian_li_dian:handover_mode'
                "
              >
                <el-form-item label="便利店类型:">
                  <el-select
                    v-model="adpas.logisticsSubType"
                    clearable
                    placeholder="请选择便利店类型"
                  >
                    <el-option
                      label="全家"
                      value="FAMI"
                    > </el-option>
                    <el-option
                      label="7-ELEVEN超商"
                      value="HILIFE"
                    > </el-option>
                    <el-option
                      label="莱尔富"
                      value="UNIMART"
                    > </el-option>
                    <el-option
                      label="7-ELEVEN冷冻店取"
                      value="UNIMARTFREEZE"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div
                class="queryItem1"
                v-if="
                  NoHuoMao &&
                  allFormData.handoverMode == 'bian_li_dian:handover_mode'
                "
              >
                <el-form-item label="便利店名称:">
                  <div></div>
                  <span v-if="allFormData.storeName">{{
                    allFormData.storeName
                  }}</span>
                  <el-button
                    @click="clickStoreAddress"
                    type="text"
                    style="padding: 0; margin-left: 10px;"
                  >请选择</el-button>
                  <el-button
                    class="subBtn"
                    type="primary"
                     style="padding:5px 10px;"
                    @click="returnPickingconfirmation"
                  >我已选择</el-button>
                </el-form-item>
              </div>
              <div
                class="queryItem1"
                v-if="
                  NoHuoMao &&
                  allFormData.handoverMode == 'bian_li_dian:handover_mode'
                "
              >
                <el-form-item
                  label="便利店地址:"
                  clearable
                >
                  <el-input
                    type="textarea"
                    rows="3"
                    v-model="allFormData.storeAddress"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
          </el-form>

          <div class="threeAddress">
            <h4>会员收货地址列表</h4>
            <el-table
              :data="addressDatas"
              size="mini"
              highlight-current-row
              border
              style="width: 98%"
            >
              <el-table-column
                type="index"
                label="序号"
                align="center"
                width="60"
              >
              </el-table-column>

              <el-table-column
                prop="storeIdNumber"
                align="center"
                label="便利店ID编号"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="storeType"
                align="center"
                label="便利店类型"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="storeName"
                align="center"
                label=" 便利店名称"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="storeAddress"
                align="center"
                label="便利店地址"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="storePhone"
                align="center"
                label="便利店联系电话"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="packupStorageName"
                align="center"
                label="自提仓库名称"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="packupAddress"
                align="center"
                label="自提仓库地址"
                min-width="100"
              >
              </el-table-column>

              <el-table-column
                prop="corporateName"
                align="center"
                label="公司名称"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="postalCode"
                align="center"
                label="邮编"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="fullName"
                align="center"
                label="收件人"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="idcardNumber"
                align="center"
                label="身份证号"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="phone"
                align="center"
                label="手机号"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="chineseName"
                label="国家"
                align="center"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="provinceName"
                align="center"
                min-width="100"
                label="洲/省"
              >
              </el-table-column>
              <el-table-column
                prop="cityName"
                align="center"
                min-width="100"
                label="城市"
              >
              </el-table-column>
              <el-table-column
                :show-overflow-tooltip="true"
                prop="address"
                align="center"
                min-width="100"
                label="详细地址"
              >
              </el-table-column>
              <el-table-column
                fixed="right"
                align="center"
                min-width="100"
                label="选择"
              >
                <template slot-scope="scope">
                  <div class="btns selectBtns">
                    <el-button
                      type="warning"
                      size="mini"
                      @click="changeAddress(scope.row)"
                    >选择</el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane
        :disabled="!this.memberId"
        label="第四包打包信息"
        name="fourth"
      >
        <div class="formBox">
          <div class="btns">
            <el-button
              type="warning"
              size="mini"
              @click="allSubmit"
            >保 存</el-button>
          </div>
          <el-form
            ref="allFormRef"
            :rules="allFormRules"
            :model="allFormData"
            label-width="120px"
          >
            <div class="newForm">
              <div class="queryItem1">
                <el-form-item
                  label="运输方式:"
                  prop="transportId"
                >
                  <el-select
                    v-model="allFormData.transportId"
                    clearable
                    @change="selectTId"
                    placeholder="请选择运输方式"
                  >
                    <el-option
                      v-for="(item, index) in transportList"
                      :label="item.transportName"
                      :key="index"
                      :value="item.channelTransportId"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="queryItem1">
                <el-form-item
                  label="出货渠道:"
                  prop="channelId"
                >
                  <el-select
                    v-model="allFormData.channelId"
                    clearable
                    placeholder="请选择出货渠道"
                  >
                    <el-option
                      v-for="(item, index) in channelList"
                      :label="item.channelName"
                      :key="index"
                      :value="item.channelId"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="queryItem1">
                <el-form-item
                  label="付款方式:"
                  prop="paymentMethodStatus"
                >
                  <el-select
                    v-model="allFormData.paymentMethodStatus"
                    clearable
                    placeholder="请选择付款方式"
                  >
                    <el-option
                      v-for="(item, index) in payType"
                      :label="item.showName"
                      :key="index"
                      :value="item.statusVal"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div
                class="queryItem1"
                v-if="NoHuoMao"
              >
                <el-form-item
                  label="客户单号:"
                  clearable
                >
                  <el-input v-model="allFormData.customerNumber"></el-input>
                </el-form-item>
              </div>
              <div class="queryItem1">
                <el-form-item
                  label="申请打包备注:"
                  clearable
                >
                  <el-input
                    type="textarea"
                    rows="3"
                    v-model="allFormData.packComment"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
          </el-form>
        </div>
      </el-tab-pane>
    </el-tabs>
    <!-- clickStoreAddress -->
    <el-dialog
      title="便利店选择"
      :visible.sync="isshowAddress"
      width="80%"
      @close="closeStore"
    >
      <div
        class="tableBox"
        style="width: 99%; padding: 0;height:600px;"
        ref="tableWrapper"
      >
        <iframe
          style="width:100%;height:100%;"
          :src="URLStore"
        ></iframe>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <!-- <el-button @click="isshowAddress = false">取 消</el-button> -->
        <el-button
          type="primary"
          @click="confirmAddressBtns"
        >确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { apiList, Api } from '@/assets/js/api'
import tools from '@/assets/js/tool'
import paging from '@/components/pagings.vue'
import EventBus from '@/eventbus.js'

export default {
  inject: ['reload'],
  components: {
    paging,
  },
  data() {
    return {
      isshowAddress: false,
      URLStore: '',
      adpas: {
        device: 0,
        isCollection: 'Y',
        logisticsSubType: 'FAMI',
        serverReplyURL: 'https://www.baidu.com/',
      },
      number: '', //打包批次号 显示
      // huiyuanId: "", //会员id 用来获取地址
      activeName: 'first',
      searchForm: {
        invitationCode: '',
        courierNumber: [],
        memberId: '',
        storageId: '',
      },
      courierNumber: '',
      storeArr: [],
      leftTable: [],
      pageStart: 1, //  分页配置-左边
      pageTotal: 20,
      pageCount: 0,
      sizeList: [10, 20, 50, 100, 200], //  分页配置
      rightTable: [], //  右边表格数据
      selectedData: [], //  表格选中的数据
      status: 2,
      allFormData: {
        addressee: '',
        areaCountryId: '',
        channelId: '',
        cityName: '',
        packComment: '',
        consigneeName: '',
        idcardNumber: '',
        itemServices: [
          {
            itemServiceId: '',
            itemServiceTitle: '',
            unitPrice: '',
          },
        ],
        packageId: [],
        paymentMethodStatus: '',
        postCode: '',
        provinceName: '',
        receivingPhone: '',
        transportId: '',
        userAddressId: '',
        corporateName: '',
        handoverMode: '',
        customerNumber: '',
        storeIdNumber: '',
        storeType: '',
        storeName: '',
        storeAddress: '',
        storePhone: '',
        packupStorageId: '',
        packupAddress: '',
      },
      huiyuanList: [], //会员下拉列表
      channelAndTransportList: [], //  渠道&运输方式下拉数据
      transportList: [], //  运输方式下拉菜单
      channelList: [], //  渠道方式下拉菜单
      payType: [], //  付款方式下拉菜单
      handoverModeList: [], //  提货方式下拉菜单
      areaContryArr: [], //  国家地区下拉列表
      nickname: '', //会员名称 显示
      memberId: '', //会员号 显示
      allFormRules: {
        provinceName: [{ required: true, message: ' ', trigger: 'blur' }],
        consigneeName: [{ required: true, message: ' ', trigger: 'blur' }],
        postCode: [{ required: true, message: ' ', trigger: 'blur' }],
        receivingPhone: [{ required: true, message: ' ', trigger: 'blur' }],
        cityName: [{ required: true, message: ' ', trigger: 'blur' }],
        addressee: [{ required: true, message: ' ', trigger: 'blur' }],
        channelId: [{ required: true, message: ' ', trigger: 'change' }],
        transportId: [{ required: true, message: ' ', trigger: 'change' }],
        areaCountryId: [{ required: true, message: ' ', trigger: 'change' }],
        paymentMethodStatus: [
          { required: true, message: ' ', trigger: 'change' },
        ],
      },
      checkList: [],
      addressDatas: [],
      serveArr: [], //费项列表
      packageInfo: {},
      billPackId: undefined,
      tunNum: '', //团号
      NoHuoMao: false,
    }
  },
  watch: {
    activeName(newvalue, newlodel) {
      if (newvalue == 'third') {
        this.getMemberDownList() //获取会员下拉列表
      }
    },
    memberId(newvalue, newlodel) {
      this.allFormData.addressee = ''
      this.allFormData.areaCountryId = ''
      this.allFormData.cityName = ''
      this.allFormData.consigneeName = ''
      this.allFormData.idcardNumber = ''
      this.allFormData.postCode = ''
      this.allFormData.provinceName = ''
      this.allFormData.receivingPhone = ''
      this.allFormData.corporateName = ''
    },
    rightTable(newval, newold) {
      if (newval.length > 0) {
        this.memberId = newval[0].memberId
        this.nickname = newval[0].nickname
      } else {
        this.memberId = ''
        this.nickname = ''
      }
    },
  },
  created() {
    this.NoHuoMao = tools.NoHuoMao() || false
    // this.memberId = this.$route.query.memberId || "";
    // this.nickname = this.$route.query.nickname || "";
    // this.number = this.$route.query.number;
    // this.billPackId = this.$route.query.id;
    // this.tunNum = this.$route.query.tunNum || "";
    // if (this.tunNum) {
    //   this.searchForm.invitationCode = this.tunNum;
    // }
    // if (this.billPackId) {
    //   this.getJoinTeamList(); //  获取详情
    //   this.getApplyDetails(); //  第一步打包明细(申请打包单关联的包裹列表)
    //   this.billPackItemServiceDetail(); //费项详情
    // }
    this.myGetStorageList() //  获取仓库下拉列表

    this.getPackageServe() //  获取打包服务项
    this.getChannelAndTransportList() //  获取渠道与运输方式下拉菜单

    this.myGetAreaContryList() //  获取国家地区
    this.myGetStatusValList('bill_pack_v1.payment_method_status', 1) //  获取支付方式
    // this.myGetStatusValList('bill_pack_v1.handover_mode', 2) //  提货方式
    this.getConfDictValDownList('handover_mode')
    const this_ = this
    EventBus.onmessage = function (res) {
      if (res.data) {
        const {
          CVSStoreID,
          LogisticsSubType,
          CVSStoreName,
          CVSAddress,
          CVSTelephone,
        } = JSON.parse(res.data) || {}
        this_.allFormData.storeIdNumber = CVSStoreID
        this_.allFormData.storeType = LogisticsSubType
        this_.allFormData.storeName = CVSStoreName
        this_.allFormData.storeAddress = CVSAddress
        this_.allFormData.storePhone = CVSTelephone
      }
    }
  },
  activated() {
    this.getNotJoinTeamList() //  获取列表
    this.activeName = 'first'
    this.memberId = this.$route.query.memberId || ''
    this.nickname = this.$route.query.nickname || ''
    this.number = this.$route.query.number
    this.billPackId = this.$route.query.id
    this.tunNum = this.$route.query.tunNum || ''
    if (this.tunNum) {
      this.searchForm.invitationCode = this.tunNum
    }
    if (this.billPackId) {
      this.getJoinTeamList() //  获取详情
      this.getApplyDetails() //  第一步打包明细(申请打包单关联的包裹列表)
      this.billPackItemServiceDetail() //费项详情
    }
  },
  methods: {
    returnPickingconfirmation() {
      Api.storeselectstoreresult().then((res) => {
        let results = res.data.result || {}
        if (typeof results === 'string') {
          console.log(JSON.parse(results))
          const {
            CVSStoreID,
            LogisticsSubType,
            CVSStoreName,
            CVSAddress,
            CVSTelephone,
          } = JSON.parse(results) || {}
          this.allFormData.storeIdNumber = CVSStoreID
          this.allFormData.storeType = LogisticsSubType
          this.allFormData.storeName = CVSStoreName
          this.allFormData.storeAddress = CVSAddress
          this.allFormData.storePhone = CVSTelephone
          // EventBus.postMessage(results)
          // setTimeout(() => {
          //   window.close()
          // }, 1000)
        } else {
          this.$message.error(`请重新选择`)
        }
      })
    },
    clickStoreAddress() {
      // this.adpas.pcServerReplyURL ='https://b9.admin.8bami.com/#/pages/getStoreName'
      // this.adpas.pcServerReplyURL ='https://www.baidu.com/'

      // 定义一个路径
      const href = this.$router.resolve({
        path: `/pages/getStoreName`,
        query: {
          adpas: JSON.stringify(this.adpas),
        },
      }).href
      // 打开新的页面 到 href 这个页面
      window.open(href, 'Pickingconfirmation_blank')
      // this.URLStore = `https://b9.admin.8bami.com/#/pages/getStoreName?adpas=${JSON.stringify(
      //   this.adpas
      // )}`
      // this.isshowAddress = true
      // window.open( this.URLStore, "Pickingconfirmation_blank");
    },
    closeStore() {
      this.URLStore = ''
      this.isshowAddress = false
    },
    confirmAddressBtns() {},
    rowclick(row, event, column) {
      this.$refs.leftTable.toggleRowSelection(row)
    },
    rowclick2(row, event, column) {
      this.$refs.rigthTable.toggleRowSelection(row)
    },
    // 选择地址
    changeAddress(row) {
      this.allFormData.userAddressId = row.userAddressId || ''
      this.allFormData.handoverMode = row.handoverMode || ''
      this.userAddressDetail(row.userAddressId)
    },
    //会员id获取收件人地址id
    userAddressList(id) {
      Api.userAddressList({ userId: id }).then((res) => {
        let addressDatas = res.data.result || []
        this.addressDatas = addressDatas
      })
    },
    //获取收件地址详情
    userAddressDetail(userAddressId) {
      Api.userAddressDetail({ userAddressId: userAddressId }).then((res) => {
        let allFormData = res.data.result
        this.allFormData.addressee = allFormData.address || ''
        this.allFormData.areaCountryId = allFormData.areaCountryId || ''
        this.allFormData.cityName = allFormData.cityName || ''
        this.allFormData.consigneeName = allFormData.fullName || ''
        this.allFormData.idcardNumber = allFormData.idcardNumber || ''
        this.allFormData.postCode = allFormData.postalCode || ''
        this.allFormData.provinceName = allFormData.provinceName || ''
        this.allFormData.receivingPhone = allFormData.phone || ''
        this.allFormData.corporateName = allFormData.corporateName || ''
        // this.allFormData.userAddressId = allFormData.userAddressId || "";
      })
    },
    //获取会员下拉列表
    getMemberDownList() {
      Api.getMemberDownList({ memberId: this.memberId }).then((res) => {
        this.huiyuanList = res.data.result.data || []
        this.userAddressList(this.huiyuanList[0].userId)
      })
    },
    myGetStorageList() {
      //  获取仓库下拉列表
      Api.getStorageList().then((res) => {
        if (res.data.status === 'success') {
          this.storeArr = res.data.result || []
        } else {
          this.$message.error(`${res.data.message}--仓库下拉列表`)
        }
      })
    },

    getNotJoinTeamList() {
      //  获取未入团列表
      this.searchForm.pageStart = this.pageStart
      this.searchForm.pageTotal = this.pageTotal
      this.searchForm.courierNumber = tools.getOrderNum(this.courierNumber)
      Api.optionalPackList(this.searchForm).then((res) => {
        if (res.data.status === 'success') {
          let leftTable = res.data.result.data || []
          if (this.rightTable.length > 0) {
            for (var a = 0; a < this.rightTable.length; a++) {
              for (var b = 0; b < leftTable.length; b++) {
                if (this.rightTable[a].packageId == leftTable[b].packageId) {
                  leftTable.splice(b, 1)
                  b--
                }
              }
            }
          }
          this.leftTable = leftTable
          this.pageCount = res.data.result.pageCount || 0
        } else {
          this.$message.error(`${res.data.message}--未入团列表`)
        }
      })
    },

    getJoinTeamList() {
      //  申请打包单参数
      Api.billPackDesc({ billPackId: this.billPackId }).then((res) => {
        if (res.data.status === 'success') {
          let allFormData = res.data.result || []
          this.allFormData.addressee = allFormData.addressee
          this.allFormData.areaCountryId = allFormData.areaCountryId
          this.allFormData.cityName = allFormData.cityName
          this.allFormData.packComment = allFormData.packComment
          this.allFormData.consigneeName = allFormData.consigneeName
          this.allFormData.idcardNumber = allFormData.idcardNumber
          this.allFormData.packageId = allFormData.packageId
          this.allFormData.paymentMethodStatus = allFormData.paymentMethodStatus
          this.allFormData.postCode = allFormData.postCode || ''
          this.allFormData.provinceName = allFormData.provinceName
          this.allFormData.receivingPhone = allFormData.receivingPhone
          this.allFormData.transportId = allFormData.channelTransportId
          this.allFormData.userAddressId = allFormData.userAddressId
          this.selectTId(allFormData.channelTransportId)
          this.allFormData.channelId = allFormData.channelId
          this.allFormData.corporateName = allFormData.companyFullname || ''
          this.allFormData.storeIdNumber = allFormData.storeIdNumber || ''
          this.allFormData.storeType = allFormData.storeType || ''
          this.allFormData.storeName = allFormData.storeName || ''
          this.allFormData.storeAddress = allFormData.storeAddress || ''
          this.allFormData.storePhone = allFormData.storePhone || ''
          this.allFormData.packupStorageId = allFormData.packupStorageId || ''
          this.allFormData.packupAddress = allFormData.packupAddress || ''
          this.allFormData.handoverMode = allFormData.handoverMode || ''
        } else {
          this.$message.error(`${res.data.message}--已入团列表`)
        }
      })
    },
    getApplyDetails() {
      //  第一步打包明细(申请打包单关联的包裹列表)
      Api.checkedPackList({ billPackId: this.billPackId }).then((res) => {
        if (res.data.status === 'success') {
          this.rightTable = res.data.result || []
        } else {
          this.$message.error(`${res.data.message}--申请打包单参数`)
        }
      })
    },
    //费项详情
    billPackItemServiceDetail() {
      Api.billPackItemServiceDetail({ billPackId: this.billPackId }).then(
        (res) => {
          if (res.data.status === 'success') {
            res.data.result.forEach((item) => {
              this.checkList.push(item.itemServiceId)
            })
          }
        }
      )
    },

    getChannelAndTransportList() {
      //  获取渠道与运输方式下拉菜单
      Api.channelAndTransportList().then((res) => {
        if (res.data.status === 'success') {
          let obj = {}
          this.transportList = res.data.result.reduce((cur, next) => {
            //  去重
            obj[next.channelTransportId]
              ? ''
              : (obj[next.channelTransportId] = true && cur.push(next))
            return cur
          }, [])
          this.channelAndTransportList = res.data.result || []
        } else {
          this.$message.error(`${res.data.message}--渠道与运输方式下拉菜单`)
        }
      })
    },

    selectTId(val) {
      //  选择运输方式下拉框时筛选渠道数据
      let arr = []
      this.allFormData.channelId = ''
      this.channelAndTransportList.forEach((item) => {
        if (item.channelTransportId === val) {
          arr.push(item)
        }
      })
      this.channelList = arr
    },
    myGetStatusValList(status, num) {
      //  获取付款方式
      Api.getStatusValList({
        tableAndFieldName: status,
      }).then((res) => {
        if (num == 1) {
          this.payType = res.data.result || []
          if (!tools.NoHuoMao()) {
            let arr = [
              { showName: '寄付', statusVal: 'ji_fu:payment_method_status' },
            ]
            this.payType = arr
          }
        } else if (num == 2) {
          //  提货方式
          // this.handoverModeList = res.data.result || []
        }
      })
    },
    getConfDictValDownList(status) {
      //  提货方式
      Api.getConfDictValDownList({
        dictType: status,
      }).then((res) => {
        let resultData = res.data.result || []
        if (status == 'handover_mode') {
          this.handoverModeList = resultData
        }
      })
    },

    myGetAreaContryList() {
      //  获取国家地区
      Api.getAreaContryList_q().then((res) => {
        if (res.data.status === 'success') {
          this.areaContryArr = res.data.result || []
        } else {
          this.$message.error(`${res.data.message}--获取国家地区`)
        }
      })
    },
    storageIdchange() {
      this.cx()
      this.allFormData.packupStorageId = this.searchForm.storageId
      let index = this.storeArr.findIndex((n) => {
        return n.id == this.searchForm.storageId
      })
      if (index > -1) {
        this.allFormData.packupAddress = this.storeArr[index].receivingAddress
      }
    },
    storageIdchange1() {
      let index = this.storeArr.findIndex((n) => {
        return n.id == this.allFormData.packupStorageId
      })
      if (index > -1) {
        this.allFormData.packupAddress = this.storeArr[index].receivingAddress
      }
    },
    cx() {
      //  未入团搜索查询
      this.pageStart = 1
      this.getNotJoinTeamList()
    },
    notJoinSelectionChange(e) {
      //  未入团表格选择
      this.selectedData = e

      this.status = 0
    },
    joinSelectionChange(e) {
      //  已入团表格选择
      this.selectedData = e
      this.status = 1
    },
    theLeft() {
      //  向左移动
      if (this.selectedData.length > 0) {
        this.selectedData.forEach((item) => {
          let index = this.rightTable.findIndex((r) => {
            return r.packageId === item.packageId
          })
          this.leftTable.push(item)
          this.rightTable.splice(index, 1)
        })
      } else {
        this.$message.warning('当前无数据！')
      }
    },
    theRight() {
      //  向右移动
      let showinfo = true
      let showinfo1 = true
      //判断勾选数据会员号是否一样
      this.selectedData.forEach((item) => {
        if (this.selectedData[0].memberId != item.memberId) {
          showinfo = false
        }
        //判断已选包裹会员号中是否存在左边勾选的会员号
        this.rightTable.forEach((x) => {
          if (x.memberId != item.memberId) {
            showinfo1 = false
          }
        })
      })
      if (showinfo) {
        if (showinfo1) {
          if (this.selectedData.length > 0) {
            this.selectedData.forEach((item) => {
              let index = this.leftTable.findIndex((r) => {
                return r.packageId === item.packageId
              })
              this.rightTable.push(item)
              this.leftTable.splice(index, 1)
            })
          } else {
            this.$message.warning('当前无数据！')
          }
        } else {
          this.$message.warning('跟已选包裹的会员号不一致')
        }
      } else {
        this.$message.warning('只能选择同一个会员号的包裹')
      }
    },
    notJionSize(val) {
      //  分页配置
      this.pageTotal = val
      this.getNotJoinTeamList()
    },
    notJoinCurrent(val) {
      this.pageStart = val
      this.getNotJoinTeamList()
    },

    handleClick(e) {
      switch (e.name) {
        case 'first':
          break
        case 'second':
          break
        case 'third':
          //  this.userAddressList(newvalue);
          // this.getMemberDownList(); //获取会员下拉列表
          break
        case 'fourth':
          break
      }
    },
    //    step2
    getPackageServe() {
      //  获取服务打包项
      Api.getPackageServeApply().then((res) => {
        if (res.data.status === 'success') {
          this.serveArr = res.data.result || []
        } else {
          this.$message.error(`${res.data.message}--获取服务打包项`)
        }
      })
    },
    allSubmit() {
      let that = this
      this.$refs['allFormRef'].validate((valid) => {
        if (valid) {
          //修改
          if (this.billPackId) {
            this.allFormData.packageIds = []
            this.allFormData.itemServiceIds = this.checkList
            this.rightTable.forEach((item) => {
              this.allFormData.packageIds.push(item.packageId)
            })
            let allFormData = this.allFormData
            let pram = {
              billPackId: this.billPackId || '', //申请打包单id
              addressee: allFormData.addressee || '', //收件人地址
              areaCountryId: allFormData.areaCountryId || '', //国家地区id
              channelId: allFormData.channelId || '', //渠道id
              cityName: allFormData.cityName || '', //市/区名称
              comment: allFormData.packComment || '', //备注
              packageIds: this.allFormData.packageIds, //包裹id数组
              consigneeName: allFormData.consigneeName, //收件人姓名
              idcardNumber: allFormData.idcardNumber, //收件人姓名
              itemServiceIds: this.checkList || [], //服务项id
              paymentMethodStatus: allFormData.paymentMethodStatus || '', //付款方式类型 ji_fu:payment_method_status=寄付
              provinceName: allFormData.provinceName || '', //州/省名称
              receivingPhone: allFormData.receivingPhone, //收件电话
              postCode: allFormData.postCode || '', //邮政编码
              companyFullname: allFormData.corporateName || '', //公司全称
              handoverMode: allFormData.handoverMode || '', //提货方式
              customerNumber: allFormData.customerNumber || '', //客户单号
              storeIdNumber:
                (allFormData.handoverMode == 'bian_li_dian:handover_mode' &&
                  allFormData.storeIdNumber) ||
                '',
              storeType:
                (allFormData.handoverMode == 'bian_li_dian:handover_mode' &&
                  allFormData.storeType) ||
                '',
              storeName:
                (allFormData.handoverMode == 'bian_li_dian:handover_mode' &&
                  allFormData.storeName) ||
                '',
              storeAddress:
                (allFormData.handoverMode == 'bian_li_dian:handover_mode' &&
                  allFormData.storeAddress) ||
                '',
              storePhone:
                (allFormData.handoverMode == 'bian_li_dian:handover_mode' &&
                  allFormData.storePhone) ||
                '',
              packupStorageId: allFormData.packupStorageId,
              packupAddress: allFormData.packupAddress,
            }
            // if (!tools.NoHuoMao) {
            //   delete pram.handoverMode
            //   delete pram.customerNumber
            // }

            let sign = tools.getSign(pram)
            pram.sign = sign
            Api.modifyBillPack(pram).then((res) => {
              if (res.data.status === 'success') {
                this.$message.success(res.data.message)

                setTimeout(() => {
                  tools.closePage()
                  if (tools.NoHuoMao()) {
                    // 新版申请打包,除了货猫
                    that.$router.push('/pages/ApplicationPackag_new')
                  } else {
                    // 货猫
                    that.$router.push(
                      '/BusinessManagement/Warehousing/ApplicationPackaging'
                    )
                  }
                  that.reload()
                }, 500)
              } else {
                this.$message.error(res.data.message)
              }
            })
          }
          //新增
          else {
            let allFormData = this.allFormData
            allFormData.packageId = new Array()
            allFormData.itemServices = new Array()
            this.rightTable.forEach((item) => {
              allFormData.packageId.push(item.packageId)
            })
            this.checkList.forEach((item) => {
              let index = this.serveArr.findIndex(
                (e) => e.itemServiceId === item
              )
              allFormData
              allFormData.itemServices.push({
                itemServiceId: this.serveArr[index].itemServiceId,
                itemServiceTitle: this.serveArr[index].title,
                unitPrice: this.serveArr[index].unitPrice,
              })
            })
            let pram = {
              addressee: allFormData.addressee || '', //收件人地址
              areaCountryId: allFormData.areaCountryId || '', //国家地区id
              channelId: allFormData.channelId, //渠道id
              cityName: allFormData.cityName || '', //市/区名称
              comment: allFormData.packComment || '', //备注
              consigneeName: allFormData.consigneeName, //收件人姓名
              idcardNumber: allFormData.idcardNumber, //收件人姓名
              itemServices: allFormData.itemServices || [], //服务项
              packageId: allFormData.packageId, //包裹id
              paymentMethodStatus: allFormData.paymentMethodStatus || '', //付款方式类型 ji_fu:payment_method_status=寄付
              postCode: allFormData.postCode | '', //邮政编码
              provinceName: allFormData.provinceName || '', //州/省名称
              receivingPhone: allFormData.receivingPhone, //收件电话
              transportId: allFormData.transportId, //运输方式id
              userAddressId: allFormData.userAddressId, //收件人地址Id
              companyFullname: allFormData.corporateName || '', //公司全称
              handoverMode: allFormData.handoverMode || '', //提货方式
              customerNumber: allFormData.customerNumber || '', //客户单号
              storeIdNumber:
                (allFormData.handoverMode == 'bian_li_dian:handover_mode' &&
                  allFormData.storeIdNumber) ||
                '',
              storeType:
                (allFormData.handoverMode == 'bian_li_dian:handover_mode' &&
                  allFormData.storeType) ||
                '',
              storeName:
                (allFormData.handoverMode == 'bian_li_dian:handover_mode' &&
                  allFormData.storeName) ||
                '',
              storeAddress:
                (allFormData.handoverMode == 'bian_li_dian:handover_mode' &&
                  allFormData.storeAddress) ||
                '',
              storePhone:
                (allFormData.handoverMode == 'bian_li_dian:handover_mode' &&
                  allFormData.storePhone) ||
                '',
              packupStorageId: allFormData.packupStorageId,
              packupAddress: allFormData.packupAddress,
            }
            // if (!tools.NoHuoMao) {
            //   delete pram.handoverMode
            //   delete pram.customerNumber
            // }
            let sign = tools.getSign(pram)
            pram.sign = sign

            Api.applyPack(pram).then((res) => {
              if (res.data.status === 'success') {
                this.$message.success(res.data.message)
                setTimeout(() => {
                  tools.closePage()
                  if (tools.NoHuoMao()) {
                    // 新版申请打包,除了货猫
                    that.$router.push('/pages/ApplicationPackag_new')
                  } else {
                    // 货猫
                    that.$router.push(
                      '/BusinessManagement/Warehousing/ApplicationPackaging'
                    )
                  }
                  that.reload()
                }, 1000)
              } else {
                this.$message.error(res.data.message)
              }
            })
          }
        } else {
          this.$message.warning('请把表单信息补充完整！')
          return false
        }
      })
    },
    nextTab() {
      if (!this.memberId) {
        this.$message.warning('已选包裹中没有检测到会员,不能申请打包')
        return false
      }
      switch (this.activeName) {
        case 'first':
          if (this.rightTable.length > 0) {
            this.activeName = 'second'
          } else {
            this.$message.warning('至少选中一个快递！')
          }
          break
        case 'second':
          this.activeName = 'third'
          break
        case 'third':
          this.activeName = 'fourth'
          // this.$refs['allFormRef'].validate((valid) => {
          //     if (valid) {
          //
          //     } else {
          //         this.$message.warning('请把表单信息补充完整！')
          //         return false;
          //     }
          // });
          break
      }
    },
  },
}
</script>

<style scoped lang="scss">
.headernumber {
  padding: 0 10px;
  box-sizing: border-box;
  margin-top: 20px;
}

.search {
  border-bottom: 1px silver solid;
  // padding: 20px 0;
  padding-bottom: 10px;

  .queryItem1 {
    width: 20%;
  }
}

.btnBox {
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateY(200%);
}

.leftTable {
  padding-right: 16px;
  height: calc(100vh - 250px);
  border-right: 1px silver solid;
}

.rightTable {
  padding-left: 16px;
  border-left: 1px silver solid;
  height: calc(100vh - 250px);
}

.transfer {
  border-bottom: 1px silver solid;
}

.titleTip {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #888888;
}

/*
    第二步
    */
.StepTow {
  margin-left: 40px;

  button {
    margin: 10px 0 60px 0;
  }
}

/*
    第三步
    */
.formBox {
  width: 100%;

  ._header {
    display: flex;
    align-items: center;
    justify-content: center;

    /deep/ .el-input__inner,
    .el-input {
      width: 400px;
    }

    margin-bottom: 10px;
  }

  .btns {
    margin: 10px;
    margin-left: 60px;
  }

  .selectBtns {
    margin: 4px auto;
  }

  .newForm {
    .queryItem1 {
      width: 360px;
      margin: 0 auto;
      margin-bottom: 10px;
    }
  }

  // .threeBox {
  //   display: flex;
  //   .threeForm {
  //     margin-right: 30px;
  //   }
  //   .threeAddress {
  //     flex: 1;
  //   }
  // }
}
</style>
